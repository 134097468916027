import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'src/components/link'
import 'src/resources/css/tailwind.css'
// styles.css must be imported AFTER tailwind.css
import 'src/resources/css/styles.scss'
import logo from 'src/resources/img/logo.jpg'
import arrowLeft from 'src/resources/svg/arrow-left.svg'

interface Props {
  subtitle?: string
}

export const Wrapper: React.FC<Props> = (props) => {
  const [isOpen, setOpen] = useState(false)
  const close = () => setOpen(false)

  return (
    <div>
      <Helmet>
        <title>PIO-CAN {props.subtitle ? ` | ${props.subtitle}` : ''}</title>
      </Helmet>

      <header className="md:container md:mx-auto">
        <div className="burger-menu" onClick={() => setOpen(true)}>
          <div className="burger-menu-symbol">
            <div className="burger-line"/>
            <div className="burger-line"/>
            <div className="burger-line"/>
          </div>
        </div>

        <Link to="/" onClick={close}>
          <img className="logo" src={logo} alt=""/>
        </Link>
      </header>

      <nav className="container mx-auto">
        <div id="main-menu" className={isOpen ? '' : 'closed'}>
          <div className="main-menu-bg" onClick={close}/>
          <div className="main-menu-container">
            <a className="close-main-menu-btn" href="#" onClick={close}><img src={arrowLeft} alt="" width="20"/></a>
            <ul className="navi-list">
              <li className="main-navi-li"><Link onClick={close} to="/ueber-uns">Wir über uns</Link></li>
              <li className="main-navi-li"><Link onClick={close} to="/leistungen">Leistungsübersicht</Link></li>
              {/*<li className="main-navi-li submenu">
                <Link onClick={close} to="/leistungen">Leistungsübersicht</Link>
                <div className="submenu-content">
                  <ul>
                    <li><Link onClick={close} to="/leistungen/filterueberwachung">Filterüberwachung</Link></li>
                    <li><Link onClick={close} to="/leistungen/datalogger">Datalogger</Link></li>
                    <li><Link onClick={close} to="/leistungen/regenerations-steuerungen">Regenerations-Steuerungen</Link></li>
                    <li><Link onClick={close} to="/leistungen/ersatzteile">Ersatzteile</Link></li>
                    <li><Link onClick={close} to="/leistungen/dpf-reinigung">DPF-Reinigung</Link></li>
                  </ul>
                </div>
              </li>*/}
              <li className="main-navi-li"><Link onClick={close} to="/kontakt">Kontakt</Link></li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container mx-auto headContact">
        RST-Engineering GmbH - Wahlerter Weg 17 - 63628 Bad Soden-Salmünster - +49 (0)6056-6215 - kontakt@rst-info.de
      </div>

      <main className="container mx-auto py-12">
        <div className="content">
          {props.children}
        </div>
      </main>

      <footer className="container mx-auto">
        <Link to="/impressum">Impressum / AGB</Link>
        <Link to="/datenschutz">Datenschutz</Link>
      </footer>
    </div>
  )
}
