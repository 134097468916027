import { Link as GatsbyLink } from 'gatsby'
import React from 'react'

interface Props {
  to: string
  className?: string
  onClick?: () => void
  target?: string
  children: React.ReactChild
}

export const Link: React.FC<Props> = (props) => {
  return (
    <GatsbyLink activeClassName='active' {...props}>
      {props.children}
    </GatsbyLink>
  )
}
